import { Text, ThemeType } from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface WaveDescriptorTextProps {
  theme: ThemeType
}

const WaveDescriptorText = styled(Text)<WaveDescriptorTextProps>`
  color: ${props => normalizeColor('text-strong', props.theme)};
  font-size: 31px;
  text-align: center;
`;

export default WaveDescriptorText;