import { WaveData, WeatherData } from "./Api";

export default class Util {
  static dayOfWeekAsString(dayIndex: number) : string {
    return ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][dayIndex] || '';
  }

  static conditionsString(waveData: WaveData) {

  }

  static localTime(timestampSeconds: number) {
    const date = new Date(timestampSeconds * 1000);
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes();

    return `${hours}:${String(minutes).padStart(2, '0')}${date.getHours() < 12 ? 'am' : 'pm'}`;
  }

  static localTimeHours(timestampSeconds: number) {
    return `${new Date(timestampSeconds * 1000).getHours() % 12 || 12}${new Date(timestampSeconds * 1000).getHours() < 12 ? 'am' : 'pm'}`
  }

  static getPrevailingWeatherCondition(weatherData: WeatherData[]) {
    const conditions = weatherData.filter((weatherData) => new Date(weatherData.timestamp * 1000).getHours() > 8 && new Date(weatherData.timestamp * 1000).getHours() < 20)
                                  .map((weatherData) => weatherData.condition);

    return conditions.sort((a, b) => conditions.filter(v => v === a).length - conditions.filter(v => v === b).length).pop();
  }
}

