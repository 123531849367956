
export default class Api {

  static DIRECT_HOST = "https://services.surfline.com";
  static PROXY_HOST  = "https://us-central1-portal-furniture.cloudfunctions.net/swellcast/XXXX";

  static getHost(useProxy: boolean) {
    return useProxy ? this.PROXY_HOST : this.DIRECT_HOST;
  }

  static async getWaveData(spotId: string, useProxy: boolean) : Promise<WaveDataResponse> {
    const waveResponse = await fetch(`${this.getHost(useProxy)}/kbyg/spots/forecasts/wave?spotId=${spotId}&days=5&intervalHours=1&sds=true`);
    const waveData     = await waveResponse.json() as WaveDataResponse;

    return waveData;
  }

  static async getWindData(spotId: string, useProxy: boolean) : Promise<WindDataResponse> {
    const windResponse = await fetch(`${this.getHost(useProxy)}/kbyg/spots/forecasts/wind?spotId=${spotId}&days=5&intervalHours=1&sds=true`);
    const windData     = await windResponse.json() as WindDataResponse;

    return windData;
  }

  static async getWeatherData(spotId: string, useProxy: boolean) : Promise<WeatherDataResponse> {
    const weatherResponse = await fetch(`${this.getHost(useProxy)}/kbyg/spots/forecasts/weather?spotId=${spotId}&days=5&intervalHours=1&sds=true`);
    const weatherData     = await weatherResponse.json() as WeatherDataResponse;

    return weatherData;
  }

  static async getTideData(spotId: string, useProxy: boolean) : Promise<TideDataResponse> {
    const tideResponse = await fetch(`${this.getHost(useProxy)}/kbyg/spots/forecasts/tides?spotId=${spotId}&days=1&intervalHours=1&sds=true`);
    const tideData     = await tideResponse.json() as TideDataResponse;

    return tideData;
  }

}

export type UnitsData = {
  temperature: string;
  tideHeight: string;
  swellHeight: string;
  waveHeight: string;
  windSpeed: string;
  pressure: string;
}

export type TideData = {
  timestamp: number;
  utcOffset: number;
  type: string;
  height: number;
}

export type TideDataResponse = {
  associated: {
    units: UnitsData;
    utcOffset: number;
    tideLocation: {
      name: string;
      min: number;
      max: number;
      lat: number;
      lon: number;
      mean: number;
    }
  }

  data: {
    tides: TideData[];
  }
}

export type SunlightData = {
  midnight: number;
  midnightUTCOffset: number;

  dawn: number;
  dawnUTCOffset: number;

  sunrise: number;
  sunriseUTCOffset: number;

  sunset: number;
  sunsetUTCOffset: number;

  dusk: number;
  duskUTCOffset: number;
}

export type WeatherData = {
  timestamp: number;
  utcOffset: number;
  temperature: number;
  condition: string;
  pressure: number;
}

export type WeatherDataResponse = {
  associated : {
    units: UnitsData;
    utcOffset: number;
    weatherIconPath: string;
    runInitializationTimestamp: number;
  }

  data: {
    sunlightTimes: SunlightData[];
    weather: WeatherData[];
  }
}

export type WindData = {
  timestamp: number;
  utcOffset: number;
  speed: number;
  direction: number;
  directionType: string;
  gust: number;
  optimalScore: number;
}

export type WindDataResponse = {
  associated : {
    units : UnitsData;
    utcOffset: number;
    location: {
      lon: number;
      lat: number;
    }
    runInitializationTimestamp: number;
  }

  data: {
    wind: WindData[];
  }
}

export type WaveData = {
    timestamp: number;
    utcOffset: number;
    surf: {
      min: number;
      max: number;
      optimalScore: number;
      plus: boolean;
      humanRelation: string;
      raw: {
        min: number;
        max: number;
      }
    }
    swells: [
      {
        height: number;
        period: number;
        direction: number;
        directionMin: number;
        optimalScore: number;
      }
    ]
}

export type WaveDataResponse = {
  associated : {
    units : UnitsData;

    utcOffset: number;

    location: {
      lon: number;
      lat: number;
    }

    forecastLocation: {
      lon: number;
      lat: number;
    }

    offshoreLocation: {
      lon: number;
      lat: number;
    }

    runInitializationTimestamp: number;
  }
  data: {
    wave: WaveData[];
  }
}