import { Text, ThemeType } from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface HeaderDateTextProps {
  theme: ThemeType
}

const HeaderDateText = styled(Text)<HeaderDateTextProps>`
  color: ${props => normalizeColor('text-xweak', props.theme)};
  font-size: 32px;
`;

export default HeaderDateText;