import { Box, Card, CardBody, DataChart } from "grommet";
import { TideData } from "../util/Api";
import Util from "../util/Util";

interface TideBoxProps {
  tideData: TideData[];
}

export default function TideBox(props: TideBoxProps) {

  return (
  <Card background="light-1" align="center" justify="center" height="330px">
    <CardBody align="center" height="330px" fill>
      <Box align="start" justify="between" direction="row" fill gap="5px">
        {
          <DataChart size="fill" 
                     data={props.tideData.map((tideDataPoint, index) => {return {height: tideDataPoint.height, time: tideDataPoint.timestamp}})} 
                     chart={[{property: 'height', type: 'line', round: true, color: 'dark-5'}]}
                     axis={{x : {property: 'time', granularity: 'medium'}, y: 'height'}}
                     guide={{x: {granularity: 'medium'}}}
                     series={[{property: 'time', render: v => Util.localTimeHours(v)}, 'height']} />
        }
      </Box>
    </CardBody>
  </Card>
  );

}