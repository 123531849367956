import { Text, ThemeType } from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface HeaderPlaceTextProps {
  theme: ThemeType
}

const HeaderPlaceText = styled(Text)<HeaderPlaceTextProps>`
  color: ${props => normalizeColor('text-strong', props.theme)};
  font-size: 52px;
  line-height: 52px;
`;

export default HeaderPlaceText;