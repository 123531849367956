import { Box } from "grommet";
import { WaveData, WaveDataResponse, WeatherData, WeatherDataResponse, WindData, WindDataResponse } from "../util/Api";
import ForecastBox from "./ForecastBox";

interface ForecastRailProps {
  waveData:    WaveDataResponse;
  windData:    WindDataResponse;
  weatherData: WeatherDataResponse;
}

export default function ForecastRail(props: ForecastRailProps) {

  function getMaxWaveForWeek(waveData: WaveData[]) : number {
    return waveData.reduce((previous, current) => {
      if (previous.surf.raw.max > current.surf.raw.max) return previous;
      else                                              return current;
    }).surf.raw.max;
  }

  const groupedWindData = props.windData.data.wind.reduce((grouped: WindData[][], wind, index) => {
    const day = Math.floor(index / 24);
    grouped[day] = grouped[day] ?? [];
    grouped[day].push(wind);
    return grouped;
  }, []);

  const groupedWaveData = props.waveData.data.wave.reduce((grouped : WaveData[][], wave, index) => {
    const day = Math.floor(index / 24);
    grouped[day] = grouped[day] ?? [];
    grouped[day].push(wave);
    return grouped;
  }, []);

  const groupedWeatherData = props.weatherData.data.weather.reduce((grouped : WeatherData[][], weather, index) => {
    const day = Math.floor(index / 24);
    grouped[day] = grouped[day] ?? [];
    grouped[day].push(weather);
    return grouped;
  }, []);

  return (
    <Box direction='row' gap='16px'>
    {
      groupedWindData.map((windDay, index) => {return {windDay: windDay, waveDay: groupedWaveData[index], weatherDay: groupedWeatherData[index]}})
      .filter((windWaveDay, index) => index !== 0)
      .map((windWaveDay, index) => {
        return <ForecastBox key={index}
                            baseConditionUrl={props.weatherData.associated.weatherIconPath} 
                            weatherData={windWaveDay.weatherDay} 
                            waveData={windWaveDay.waveDay} 
                            windData={windWaveDay.windDay} 
                            maxWaveHeight={getMaxWaveForWeek(props.waveData.data.wave)} 
                            waveHeightUnits={props.waveData.associated.units.swellHeight}/>
      })
    }
    </Box>
  );
}