import { Text, ThemeType } from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface TemperatureSummaryTextProps {
  theme: ThemeType
}

const TemperatureSummaryText = styled(Text)<TemperatureSummaryTextProps>`
  color: ${props => normalizeColor('text-strong', props.theme)};
  font-size: 140px;
  line-height: 140px;
`;

export default TemperatureSummaryText;