import { useEffect, useState } from 'react';
import { Grommet } from 'grommet';
import Api, { TideDataResponse, WaveDataResponse, WeatherDataResponse, WindDataResponse } from './util/Api';
import AppLayout from './AppLayout';


const theme = {
  global: {
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    }
  }

}

function App() {

  const [waveData, setWaveData]       = useState<WaveDataResponse|undefined>(undefined);
  const [windData, setWindData]       = useState<WindDataResponse|undefined>(undefined);
  const [weatherData, setWeatherData] = useState<WeatherDataResponse|undefined>(undefined);
  const [tideData, setTideData]       = useState<TideDataResponse|undefined>(undefined);

  const searchParams = new URLSearchParams(window.location.search);
  const spotId       = searchParams.get("spotId")  || "5842041f4e65fad6a770881e";
  const spotName     = searchParams.get("spotName") || "Topanga Beach";
  const batteryLevel = parseInt(searchParams.get("batteryLevel") || "50");
  const useProxy     = (searchParams.get("useProxy") || "false") === "true";


  useEffect(() => {
    async function getData() {
      const waveData    = Api.getWaveData(spotId, useProxy);
      const windData    = Api.getWindData(spotId, useProxy);
      const weatherData = Api.getWeatherData(spotId, useProxy);
      const tideData    = Api.getTideData(spotId, useProxy);

      const combined = await Promise.all([waveData, windData, weatherData, tideData]);

      setWaveData(combined[0]);
      setWindData(combined[1]);
      setWeatherData(combined[2]);
      setTideData(combined[3]);
    }

    getData();
  }, [spotId, useProxy]);

  return (
    <Grommet theme={theme} full>

      { waveData && windData && weatherData && tideData && <AppLayout waveData={waveData} windData={windData} tideData={tideData} weatherData={weatherData} spotName={spotName} batteryLevel={batteryLevel} /> }

    </Grommet>
  );
}

export default App;



