import { Box, Card, CardBody, Chart, Stack, ThemeContext } from "grommet";
import { Star } from "grommet-icons";
import { useContext } from "react";
import DateText from "../styled/DateText.styled";
import WaveDescriptorText from "../styled/WaveDescriptorText.styled";
import WaveHeightText from "../styled/WaveHeightText.styled";
import { WaveData, WeatherData, WindData } from "../util/Api";
import Util from "../util/Util";

interface ForecastBoxProps {
  waveData:    WaveData[];
  windData:    WindData[];
  weatherData: WeatherData[];

  baseConditionUrl: string;

  maxWaveHeight: number;
  waveHeightUnits: string;
}

export default function ForecastBox(props: ForecastBoxProps) {

  const theme = useContext(ThemeContext);

  function getBiggestTimeSliceForDay(waveDay: WaveData[]) {
    return waveDay.reduce((previous, current) => {
      if (current.surf.max > previous.surf.max) return current;
      else                                      return previous;
    });
  }

  function getWaveHeightTextForDay(waveDay: WaveData[], unit: string) : string {
    const biggestTimeSlice = getBiggestTimeSliceForDay(waveDay);
    return `${biggestTimeSlice.surf.min}-${biggestTimeSlice.surf.max}${unit.toLowerCase()}`;
  }

  function getWaveDescriptorForDay(waveDay: WaveData[]) : string {
    const biggestTimeSlice = getBiggestTimeSliceForDay(waveDay);
    return biggestTimeSlice.surf.humanRelation;
  }

  function getConditionsForDay(windDay: WindData[], waveDay: WaveData[]) {
    return [windDay[9].optimalScore + waveDay[9].surf.optimalScore,
            windDay[12].optimalScore + waveDay[12].surf.optimalScore,
            windDay[4].optimalScore + waveDay[4].surf.optimalScore];
  }

  const waveHeights = props.waveData.map((wave) => wave.surf.raw.max);
  const conditions  = getConditionsForDay(props.windData, props.waveData);

  return (
    <Card background="light-1" align="center" justify="center" >
      <CardBody align="center" height="470px">
        <Stack fill>
          <Chart
            bounds={[[0, waveHeights.length-1], [0, props.maxWaveHeight*2]]}
            type="area"
            round={true}
            color="light-3"
            values={waveHeights.map((height, index) => {return {value: [index, height]}})}
            size={{height: "470px"}}
          />
`
          <Box align="center" justify="between" pad="small" fill>
            <Box direction="column" align="center" justify="center" gap="10px">
              <DateText theme={theme}>{Util.dayOfWeekAsString(new Date(props.waveData[1].timestamp * 1000).getDay())}</DateText>

              <img src={`${props.baseConditionUrl}/${Util.getPrevailingWeatherCondition(props.weatherData)}.svg`} 
                  alt="Weather" 
                  style={{filter: 'grayscale(100%)', width: "50px"}} />
            </Box>


            <Box direction="column" align="center" gap="15px">
              <WaveHeightText>{getWaveHeightTextForDay(props.waveData, props.waveHeightUnits)}</WaveHeightText>
              <WaveDescriptorText>{getWaveDescriptorForDay(props.waveData)}</WaveDescriptorText>
            </Box>

            <Box>

            </Box>
          </Box>


          <Box direction="row" align="end" justify="between" fill pad={{horizontal: "40px"}}>
              <Box direction="column" align="center">
                {[...Array(conditions[0])].map((e, i) => <Star size="medium" />)}
              </Box>
              <Box direction="column" align="center">
                {[...Array(conditions[1])].map((e, i) => <Star size="medium" />)}
              </Box>
              <Box direction="column" align="center">
                {[...Array(conditions[2])].map((e, i) => <Star size="medium" />)}
              </Box>
            </Box>

        </Stack>
      </CardBody>
    </Card>
  );
}