import { Box, Card, CardBody, Table, TableBody, TableCell, TableRow } from "grommet";
import TemperatureSummaryText from "../styled/TemperatureSummaryText.styled";
import { SunlightData, TideData, UnitsData, WaveData, WeatherData, WindData } from "../util/Api";
import sunrise from "../assets/sunrise.svg";
import sunset  from "../assets/sunset.svg";
import highTide from "../assets/high-tide.svg";
import lowTide from "../assets/low-tide.svg";
import Util from "../util/Util";
import TimeText from "../styled/TimeText.styled";

interface TodayAtmosphericConditionsBoxProps {
  waveData: WaveData[];
  windData: WindData[];
  tideData: TideData[];
  highlightHours: number[];

  units: UnitsData;
  baseConditionUrl: string;

  weatherData:  WeatherData[];
  sunlightData: SunlightData;
}


export default function TodayAtmosphericConditionsBox(props: TodayAtmosphericConditionsBoxProps) {

  function getHighTemp(weatherData: WeatherData[]) {
    return weatherData.map((weatherDataPoint) => weatherDataPoint.temperature)
                      .reduce((previous, current) => Math.max(previous, current));
  }

  const tideInflections = props.tideData.filter((tideDataPoint) => tideDataPoint.type === "LOW" || tideDataPoint.type === "HIGH");

  if (tideInflections.length % 2 !== 0) {
    tideInflections.push({timestamp: 0, utcOffset: 0, height: 0, type: "PLACEHOLDER"});
  }

  const groupedTideInflections = tideInflections.reduce((grouped : TideData[][], tideDataPoint, index) => {
    index % 2 === 0 ? grouped.push([tideDataPoint]) : grouped[grouped.length-1].push(tideDataPoint);
    return grouped;
  }, []);

  return (
    <Card background="light-1" align="center" justify="center" >
      <CardBody align="center">

          <Box direction="row" align="center" justify="center" pad="20px">
            <img src={`${props.baseConditionUrl}/${Util.getPrevailingWeatherCondition(props.weatherData)}.svg`} 
                alt="Weather" 
                style={{filter: 'brightness(0%)', width: "300px"}} />

            <Box direction="column" align="center" gap="16px">
                <TemperatureSummaryText>{`${Math.round(getHighTemp(props.weatherData))}°${props.units.temperature}`}</TemperatureSummaryText>

                <Table>
                  <TableBody>              
                    <TableRow>
                      <TableCell><img src={sunrise} alt="Sunrise" style={{width: "50px"}}/></TableCell>
                      <TableCell><TimeText>{Util.localTime(props.sunlightData.sunrise)}</TimeText></TableCell>
                      <TableCell><img src={sunset} alt="Sunset" style={{width: "50px"}}/></TableCell>
                      <TableCell><TimeText>{Util.localTime(props.sunlightData.sunset)}</TimeText></TableCell>
                    </TableRow>


                    {
                      groupedTideInflections.map((tideInflectionRow) => (
                        <TableRow>
                          {
                            tideInflectionRow.map((tideDataPoint) => (
                              <>                        
                                <TableCell>
                                  <img src={tideDataPoint.type === "LOW" ? lowTide : highTide} alt={tideDataPoint.type} style={{width: "50px"}}/>
                                </TableCell>
                                <TableCell>
                                  <TimeText>{Util.localTime(tideDataPoint.timestamp)}</TimeText>
                                </TableCell>
                              </>
                            ))
                          }
                        </TableRow>
                      ))
                    }

                  </TableBody>
                </Table>
              </Box>
            </Box>

      </CardBody>
    </Card>

  );
}