import { Box, Stack } from "grommet";
import HeaderDateText from "../styled/HeaderDateText.styled";
import HeaderPlaceText from "../styled/HeaderPlaceText.styled";
import batteryOneHundred from "../assets/battery_100.svg";
import batterySeventyFive from "../assets/battery_75.svg";
import batteryFifty from "../assets/battery_50.svg";
import batteryTwentyFive from "../assets/battery_25.svg";
import batteryZero from "../assets/battery_0.svg";

interface HeaderBoxProps {
  name: string;
  batteryPower: number;
}

export default function HeaderBox(props: HeaderBoxProps) {

  function batteryIconForLevel(level: number) {
    if      (level > 75) return batteryOneHundred;
    else if (level > 50) return batterySeventyFive;
    else if (level > 25) return batteryFifty;
    else if (level > 15) return batteryTwentyFive;
    else                 return batteryZero;
  }

  return (
    <Stack fill="horizontal" style={{height: "55px"}}>
      <Box direction="row" fill="horizontal" align="center" justify="start">
        <HeaderDateText>{new Date().toDateString()}</HeaderDateText>
      </Box>
      <Box direction="row" fill="horizontal" align="center" justify="center">
        <HeaderPlaceText>{props.name}</HeaderPlaceText>
      </Box>
      <Box direction="row" fill="horizontal" align="center" justify="end">
        <img src={batteryIconForLevel(props.batteryPower)} alt="50%" width="50px" />
      </Box>

    </Stack>
  );
}