import { Box, Card, CardBody, Table, TableBody, TableCell, TableRow } from "grommet";
import { LinkDown, LinkPrevious, LinkUp, Star } from "grommet-icons";
import TimeText from "../styled/TimeText.styled";
import WaveDescriptorText from "../styled/WaveDescriptorText.styled";
import WaveHeightText from "../styled/WaveHeightText.styled";
import { UnitsData, WaveData, WindData } from "../util/Api";

interface TodaySurfConditionsBoxProps {
  waveData: WaveData[];
  windData: WindData[];
  highlightHours: number[];
  units: UnitsData;
}

export default function TodaySurfConditionsBox(props: TodaySurfConditionsBoxProps) {

  function getWaveDataPointForHour(hour: number, waveData: WaveData[]) : WaveData | undefined {
    return waveData.find((waveDataPoint) => new Date(waveDataPoint.timestamp * 1000).getHours() === hour);
  }

  function getWindDataPointForHour(hour: number, windData: WindData[]) : WindData | undefined {
    return windData.find((windDataPoint) => new Date(windDataPoint.timestamp * 1000).getHours() === hour);
  }

  function getStarCountForHour(hour: number, waveData: WaveData[], windData: WindData[]) {
    return (getWaveDataPointForHour(hour, waveData)?.surf.optimalScore || 0) +
           (getWindDataPointForHour(hour, windData)?.optimalScore || 0);            
  }

  function getWaveHeightForHour(hour: number, waveData: WaveData[], units: UnitsData) {
    const waveDataPoint = getWaveDataPointForHour(hour, waveData);

    return `${waveDataPoint?.surf.min || 0}-${waveDataPoint?.surf.max || 0}${units.swellHeight.toLowerCase()}`;
  }

  function getWaveDescritprForHour(hour: number, waveData: WaveData[]) {
    return getWaveDataPointForHour(hour, waveData)?.surf.humanRelation;
  }

  function getWindSpeedForHour(hour: number, windData: WindData[], units: UnitsData) {
    return `${Math.round(getWindDataPointForHour(hour, windData)?.speed || 0)}${units.windSpeed.toLowerCase()}`;
  }

  function getWindDirectionForHour(hour: number, windData: WindData[]) {
    switch ((getWindDataPointForHour(hour, windData)?.directionType || "Onshore").toLowerCase()) {
      case "onshore"     : return <LinkDown     size="medium" />
      case "offshore"    : return <LinkUp       size="medium" />
      case "cross-shore" : return <LinkPrevious size="medium" />
    }
  }

  return (
    <Card background="light-1" align="center" justify="center" >
    <CardBody align="center" justify="center">
      <Box justify="center" align="center" pad="20px">
    <Table>
      <TableBody>
        {
          props.highlightHours.map((highlightHour) => {
            return (
              <TableRow>
                <TableCell><TimeText>{`${highlightHour % 12 || 12}${highlightHour < 12 ? "am" : "pm"}`}</TimeText></TableCell>
                <TableCell style={{minHeight: "45px"}}>
                  <Box direction="row" align="center">
                    {
                      [...Array(getStarCountForHour(highlightHour, props.waveData, props.windData))].map((e, i) => <Star size="large" />)
                    }
                  </Box>
                </TableCell>
                <TableCell><WaveHeightText>{getWaveHeightForHour(highlightHour, props.waveData, props.units)}</WaveHeightText></TableCell>
                <TableCell><WaveDescriptorText>{getWaveDescritprForHour(highlightHour, props.waveData)}</WaveDescriptorText></TableCell>
                <TableCell>
                  <Box direction="row" align="center" justify="start" gap="10px">
                    {getWindDirectionForHour(highlightHour, props.windData)}
                    <WaveDescriptorText>{getWindSpeedForHour(highlightHour, props.windData, props.units)}</WaveDescriptorText>
                  </Box>
                </TableCell>
              </TableRow>  
            );
          })
        }
      </TableBody>
    </Table>
    </Box></CardBody>
    </Card>

  );
}