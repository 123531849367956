import { Box, Card, CardBody, Text } from "grommet";
import { WaveData } from "../util/Api";
import Util from "../util/Util";

interface SwellBoxProps {
  waveData: WaveData[];
}

export default function SwellBox(props: SwellBoxProps) {

  const maxWaveHeight = props.waveData.map((waveData) => waveData.surf.raw.max).reduce((previous, current) => Math.max(previous, current));

  return (
    <Card background="light-1" align="center" justify="center" height="330px">
      <CardBody align="center" height="330px" fill>
        <Box align="start" justify="between" direction="row" fill gap="5px">
          {
            props.waveData.map((wave, index) => {              
              return (
                <Box align="center" direction="column" justify="end" key={index} fill>
                  <Text>{wave.surf.max}</Text>
                  <Box width="50px" height={`${(wave.surf.raw.max / maxWaveHeight) * 100}%`} background="dark-5" round={{corner: "top", size: "xsmall"}} />
                  <Text>{(index) % 3 === 0 && index !== 0 ? Util.localTimeHours(wave.timestamp) : " "}</Text>
                </Box>
              )
            })
          }
        </Box>
      </CardBody>
    </Card>
  );
}