import { Text, ThemeType } from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface WaveHeightTextProps {
  theme: ThemeType
}

const WaveHeightText = styled(Text)<WaveHeightTextProps>`
  color: ${props => normalizeColor('text-strong', props.theme)};
  font-size: 38px;
  font-weight: 800;
`;

export default WaveHeightText;