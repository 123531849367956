import { Box, Grid } from "grommet";
import ForecastRail from "./components/ForecastRail";
import HeaderBox from "./components/HeaderBox";
import SwellBox from "./components/SwellBox";
import TideBox from "./components/TideBox";
import TodayAtmosphericConditionsBox from "./components/TodayAtmosphericConditionsBox";
import TodaySurfConditionsBox from "./components/TodaySurfConditionsBox";
import { TideDataResponse, WaveDataResponse, WeatherDataResponse, WindDataResponse } from "./util/Api";

interface AppLayoutProps {
  windData:     WindDataResponse;
  waveData:     WaveDataResponse;
  tideData:     TideDataResponse;
  weatherData:  WeatherDataResponse;
  spotName:     string;
  batteryLevel: number;
}

export default function AppLayout(props: AppLayoutProps) {

  const todayWaveData    = props.waveData.data.wave.filter((wave, index) => index < 24);
  const todayWindData    = props.windData.data.wind.filter((wind, index) => index < 24)
  const todayWeatherData = props.weatherData.data.weather.filter((weather, index) => index < 24);

  return (
    <Box id="app-loaded" pad="16px" direction="column" gap="16px">
      <HeaderBox name={props.spotName} batteryPower={props.batteryLevel}  />

      <Grid columns={{count: 2, size: "auto"}} gap="small">
        <TodayAtmosphericConditionsBox windData={todayWindData} 
                                       waveData={todayWaveData}
                                       highlightHours={[9, 12, 16]}
                                       units={props.waveData.associated.units}
                                       baseConditionUrl={props.weatherData.associated.weatherIconPath}
                                       weatherData={todayWeatherData} 
                                       tideData={props.tideData.data.tides}
                                       sunlightData={props.weatherData.data.sunlightTimes[0]} 
                                      />

        <TodaySurfConditionsBox waveData={todayWaveData} 
                                windData={todayWindData} 
                                units={props.waveData.associated.units} 
                                highlightHours={[8,10,11,12,14,16,18]} />
      </Grid>

      <Grid columns={{count: 2, size: "auto"}} gap="small">
        <SwellBox waveData={props.waveData.data.wave.filter((wave, index) => {
          return index < 24;
        })} />


        <TideBox tideData={props.tideData.data.tides} />
      </Grid>


    <ForecastRail waveData={props.waveData} windData={props.windData} weatherData={props.weatherData} />
  </Box>
  );


}