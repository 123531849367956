import { Text, ThemeType } from "grommet";
import { normalizeColor } from "grommet/utils";
import styled from "styled-components";

interface DateTextProps {
  theme: ThemeType
}

const DateText = styled(Text)<DateTextProps>`
  color: ${props => normalizeColor('text-xweak', props.theme)};
  font-size: 32px;
`;

export default DateText;